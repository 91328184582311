<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3" style="text-align: justify">
        Imagine that you have a hypothetical base,
        <chemical-latex content="B," />
        that can accept two different protons to form
        <chemical-latex content="BH^+" />
        and
        <chemical-latex content="BH2^{2+}" />
        according to the following balanced equations:
      </p>

      <table class="mb-2 pl-8">
        <tr style="height: 40px">
          <td style="vertical-align: top">
            <chemical-latex content="B(aq) + H2O(l) <=> BH^+(aq) + OH^-(aq)" />
          </td>
          <td style="vertical-align: top">
            <stemble-latex :content="'$\\qquad\\text{K}_{\\text{b}_{\\text{1}}}=\\,\\,\\,$'" />
          </td>
          <td style="vertical-align: top">
            <number-value :value="Kb1" />
          </td>
        </tr>
        <tr style="height: 40px">
          <td style="vertical-align: top">
            <chemical-latex content="BH^+(aq) + H2O(l) <=> BH2^{2+}(aq) + OH^-(aq)" />
          </td>
          <td style="vertical-align: top">
            <stemble-latex :content="'$\\qquad\\text{K}_{\\text{b}_{\\text{2}}}=\\,\\,\\,$'" />
          </td>
          <td style="vertical-align: top">
            <number-value :value="Kb2" />
          </td>
        </tr>
      </table>

      <p class="mb-2">
        If you start with a
        <number-value :value="concentration" />
        <stemble-latex :content="'$\\text{M}$'" />
        aqueous solution of
        <stemble-latex content="$\text{B}$" />, determine the concentration of each species and the
        pH of the solution when it reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.bconc"
        class="mb-3"
        :prepend-text="'$\\ce{[B]}\\hspace{0.76cm}:$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bhconc"
        class="mb-3"
        :prepend-text="'$\\ce{[BH^+]}\\hspace{0.27cm}:$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bh2conc"
        class="mb-3"
        :prepend-text="'$\\ce{[BH2^{2+}]}:$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.ohconc"
        class="mb-3"
        :prepend-text="'$\\ce{[OH^-]}\\hspace{0.25cm}:$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pH"
        :prepend-text="'$\\text{pH}\\hspace{0.76cm}:$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question313',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        bconc: null,
        bhconc: null,
        bh2conc: null,
        ohconc: null,
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
    Kb1() {
      return this.taskState.getValueBySymbol('Kb1').content;
    },
    Kb2() {
      return this.taskState.getValueBySymbol('Kb2').content;
    },
  },
};
</script>
